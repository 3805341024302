import type { ProductsProps } from './data';

import React from 'react';

import { Divider, Link, Text, VStack } from '@/components';
import { useTranslation } from '@/hooks';
import { routes } from '@/statics';

const Products: ProductsProps = ({ categories }) => {
  const { t } = useTranslation('components.footer.products');
  return (
    <>
      <VStack alignItems={'start'}>
        <Text color="text" fontWeight="medium" fontSize={'sm'}>
          {t('products')}
        </Text>
        <Divider w="100px" color={'gray.700'} borderWidth={0.2} />
        <VStack
          spacing={'20px'}
          alignItems={'start'}
          mt={{ base: 0, md: '0.5rem' }}
        >
          {categories.map((category) => (
            <Link key={category.id} href={routes.category.href(category.code)}>
              <Text color="text" fontWeight="medium" fontSize={'sm'}>
                {category.title}
              </Text>
            </Link>
          ))}
        </VStack>
      </VStack>
    </>
  );
};

export default Products;

import { Box, Image, Link, Text } from '@/components';
import { routes } from '@/statics';

const SearchProductCard: React.FC<{
  image: string;
  alt: string;
  title: string;
  code: number;
}> = ({ image, alt, title, code }) => {
  return (
    <Link href={routes.product.href(code)}>
      <Box
        display="flex"
        alignItems="center"
        paddingBlock="16px"
        paddingInline="27px"
      >
        <Box position="relative" width="100px" height="100px">
          <Image src={image} alt={alt} fill />
        </Box>
        <Text
          paddingInlineStart={4}
          fontSize={{ base: 'xs', md: 'lg' }}
          fontWeight={{ base: 'normal', md: '500' }}
        >
          {title}
        </Text>
      </Box>
    </Link>
  );
};
export default SearchProductCard;

import type { IHeaderSearch } from './data';

import React from 'react';
import { Search } from 'react-feather';

import ModalSearch from '../ModalSearch';

import { Button, IconButton } from '@/components';
import { useTranslation } from '@/hooks';
import SearchIcon from '@/svgs/header/search.svg';

const HeaderSearch: IHeaderSearch = () => {
  const { t } = useTranslation('components.header');

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <Button
        display={{ base: 'flex', md: 'none' }}
        width={{ base: '70vw', md: '60vw' }}
        bgColor="grayAlpha.200"
        justifyContent="start"
        size="sm"
        onClick={() => setIsOpen(true)}
        leftIcon={<Search size={24} style={{ stroke: 'gray.800' }} />}
        variant="unstyled"
        paddingInline={4}
        lineHeight={1.2}
      >
        {t('searchPlaceholder')}
      </Button>
      <IconButton
        onClick={() => setIsOpen(true)}
        color="gray.700"
        display={{ base: 'none', md: 'block' }}
        _hover={{ bgColor: 'transparent' }}
        aria-label="Search"
        bgColor="transparent"
        icon={<SearchIcon />}
      />
      {isOpen && <ModalSearch isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};
export default HeaderSearch;

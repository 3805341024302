import { Alert, Container, Link, Stack, Text } from '@/components';
import { useProfile, useTranslation } from '@/hooks';
import { routes } from '@/statics';

const UnverifiedBar: React.FC = () => {
  const { t } = useTranslation('pages.unverifiedBar');

  const { data: profile } = useProfile();

  const warnings = {
    'not-verified-identity': {
      title: t('notVerifiedIdentity'),
      linkTitle: t('verifiedIdentityRequest'),
      url: routes.identityUploadFile.url,
      active: profile && !profile?.is_verified_document,
    },
    'not-verified-mobile': {
      title: t('notVerifiedMobile'),
      linkTitle: t('verifiedMobileRequest'),
      url: routes.identitySubmitOtp.url,
      active: profile && !profile?.is_verified_sms,
    },
    'not-verified-emta': {
      title:
        profile?.verification_status === 'in_progress'
          ? t('emtaStatusInProgress')
          : t('notVerifiedEmta'),
      linkTitle: t('verifiedEmtaRequest'),
      url: ['pending', 'invalid'].includes(profile?.verification_status || '')
        ? routes.identitySubmitEmta.url
        : null,
      active: profile && profile?.verification_status !== 'valid',
    },
    'not-verified-sheba-number': {
      title: t('notVerifiedSheba'),
      linkTitle: t('verifiedShebaRequest'),
      url: routes.personalInformation.url,
      active: profile && profile?.bank_account_status === 'invalid',
    },
  };

  return (
    <Container w="100%" overflowX="auto" display="flex" flexDir="row" gap={4}>
      {Object.entries(warnings)
        .filter(([, warning]) => warning.active)
        .map(([key, value]) => {
          return (
            <Alert
              key={key}
              minW={{ base: 'max-content', xl: 'unset' }}
              w="max-content"
              py={0}
              px={2}
              my={2}
              h={8}
              status="warning"
            >
              <Stack flexDir="row" gap={2}>
                <Text fontSize="small">{value.title}</Text>
                {value.url && (
                  <Link href={value.url}>
                    <Text color="tertiary.800" fontSize="small">
                      {value.linkTitle}
                    </Text>
                  </Link>
                )}
              </Stack>
            </Alert>
          );
        })}
    </Container>
  );
};

export default UnverifiedBar;

import type { TitlesMobileProps } from './data';

import React from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
} from '@/components';
import { useTranslation } from '@/hooks';
import { footerLinks, routes, toolsLinks } from '@/statics';

const TitlesMobile: TitlesMobileProps = ({ categories, plansFile }) => {
  const { t } = useTranslation('components.footer');

  const data = [
    {
      id: 1,
      title: t('products.products'),
      desc: categories.map((item) => ({
        title: item.title,
        url: routes.category.href(item.code),
        type: '',
      })),
    },
    {
      id: 2,
      title: t('contactLia.contactLia'),
      desc: footerLinks.StaticsDataContactLia,
    },
    {
      id: 3,
      title: t('tools.tools'),
      desc: toolsLinks,
    },
    {
      id: 4,
      title: t('termsAndConditions.termsAndConditions'),
      desc: footerLinks.StaticsDataTermsAndConditions,
    },
  ];
  return (
    <>
      <Accordion allowToggle>
        {data.map((elem) => (
          <AccordionItem key={elem.id}>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton
                    color={isExpanded ? 'primary.500' : 'text'}
                    // bg={isExpanded ? '#fff !important' : '#fff'}
                    borderBottom={isExpanded ? '1px solid !important' : 'none'}
                    borderBottomColor={
                      isExpanded ? 'gray.400 !important' : 'unset'
                    }
                    _hover={{ bg: 'white' }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="right"
                      fontSize={'xs'}
                      color="text"
                    >
                      {elem.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                {elem.desc.map((item, index) => (
                  <AccordionPanel
                    key={index}
                    pb={4}
                    color="text"
                    fontSize={'xs'}
                  >
                    <Link href={item?.type === 'plans' ? plansFile : item.url}>
                      {item.title}
                    </Link>
                  </AccordionPanel>
                ))}
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default TitlesMobile;

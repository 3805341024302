import React from 'react';

import StaticData from '@/app/(pages)/dashboard/@components/StaticData';
import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  IconButton,
  Link,
} from '@/components';
import { routes } from '@/statics';
import LogoLia from '@/svgs/liateam-logo.svg';

const DashboardSideSheet: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  return (
    <>
      <Drawer
        size={{ base: 'full', md: 'xs' }}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
      >
        <DrawerContent
          marginBottom="68px"
          display={{ base: isOpen ? 'block' : 'none', md: 'none' }}
          boxShadow="unset !important"
          backgroundColor={{ base: 'white', md: 'gray.100' }}
          overflowY={{ base: 'auto', md: 'hidden' }}
          width={{ base: '100%' }}
          containerProps={{
            w: 'full',
            h: '100vh',
            position: 'absolute',
            overflowY: 'auto',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          bottom={{ md: 'unset !important' }}
          borderRadius={{ md: '15px' }}
          maxHeight={'unset'}
        >
          <Flex
            mt={'14px'}
            justifyContent={'center'}
            alignItems={'center'}
            h={'transparent'}
          >
            <DrawerCloseButton top={'1.25rem'} />
            <IconButton
              mt={'10px'}
              _hover={{ bgColor: 'transparent' }}
              aria-label="Logo Lia"
              bgColor={'transparent'}
              icon={
                <Link href={routes.home.url}>
                  <Box style={{ paddingRight: 0, border: 0 }}>
                    <LogoLia height={45} width={45} />
                  </Box>
                </Link>
              }
            />
            <Box></Box>
          </Flex>
          <Box mb="50px">
            <StaticData isDashboardPage={false} onClose={onClose} />
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DashboardSideSheet;

import React from 'react';

import { Divider, Grid, Link, Text, VStack } from '@/components';
import { useTranslation } from '@/hooks';
import { footerLinks } from '@/statics';

const TermsAndConditions: React.FC<{ plansFile: string }> = ({ plansFile }) => {
  const { t } = useTranslation('components.footer.termsAndConditions');

  return (
    <>
      <VStack alignItems={'start'}>
        <Text
          color="text"
          fontWeight="medium"
          fontSize={'sm'}
          display={'flex'}
          alignItems={'start'}
          justifyContent={'start'}
        >
          {t('termsAndConditions')}
        </Text>
        <Divider w="445px" color={'gray.700'} borderWidth={0.2} />

        <Grid
          mt={{ base: 0, md: '0.5rem' }}
          templateColumns={'repeat(2, 1fr)'}
          columnGap="40px"
          rowGap="20px"
          justifyContent={'start'}
          alignItems={'start'}
        >
          {footerLinks.StaticsDataTermsAndConditions.map((data, index) => (
            <Link
              key={index}
              href={data.type === 'plans' ? plansFile : data.url}
            >
              <Text
                textAlign={'right'}
                color="text"
                fontWeight="medium"
                fontSize={'sm'}
              >
                {data.title}
              </Text>
            </Link>
          ))}
        </Grid>
      </VStack>
    </>
  );
};

export default TermsAndConditions;

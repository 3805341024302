import i18n from '@/i18n';

const toolsLinks = [
  {
    title: i18n.t('components.staticsData.intro'),
    url: process.env.NEXT_PUBLIC_LIATEAM_INTRO!,
    type: '',
  },
  {
    title: i18n.t('components.staticsData.catalog'),
    url: process.env.NEXT_PUBLIC_CATALOG_PRODUCTS!,
    type: '',
  },
  {
    title: i18n.t('components.staticsData.mets'),
    url: process.env.NEXT_PUBLIC_LIATEAM_METS!,
    type: '',
  },
  {
    title: i18n.t('components.staticsData.gallery'),
    url: process.env.NEXT_PUBLIC_LIATEAM_MEETUP!,
    type: '',
  },
];

export default toolsLinks;

import type { IAlert } from './types';

import { Alert as CkAlert } from '@chakra-ui/react';

import { AlertDescription, AlertIcon, Spinner } from '@/components';
import AlertErrorIcon from '@/svgs/alert-error.svg';
import AlertInfoIcon from '@/svgs/alert-info.svg';
import AlertSuccessIcon from '@/svgs/alert-success.svg';
import AlertWarningIcon from '@/svgs/alert-warning.svg';

const Alert: IAlert = ({ children, status, ...props }) => {
  const iconByStatus = {
    info: <AlertInfoIcon />,
    warning: <AlertWarningIcon />,
    error: <AlertErrorIcon />,
    success: <AlertSuccessIcon />,
    loading: <Spinner />,
  };
  return (
    <CkAlert bg={`${status}.300`} status={status} {...props}>
      {status && (
        <AlertIcon fill={`${status}.500`}>{iconByStatus[status]}</AlertIcon>
      )}
      <AlertDescription>{children}</AlertDescription>
    </CkAlert>
  );
};

export default Alert;

import EventBar from '../EventBar';
import HeaderDesktop from '../HeaderDesktop';
import HeaderMobile from '../HeaderMobile';
import UnverifiedBar from '../UnverifiedBar';

const Header: React.FC = () => {
  return (
    <>
      <UnverifiedBar />
      <HeaderMobile />
      <HeaderDesktop />
      <EventBar />
    </>
  );
};

export default Header;

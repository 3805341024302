import type { IBrand, IErrorResponse } from '@/types';

import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { urls } from '@/statics';

const useBrands = (): UseQueryResult<IBrand[], IErrorResponse> => {
  return useQuery({
    queryKey: [urls.brands],
    staleTime: Infinity,
    gcTime: Infinity,
  });
};

export default useBrands;

import i18n from '@/i18n';
import { routes } from '@/statics';

const footerLinks = {
  StaticsDataContactLia: [
    { title: routes.blogs.title, url: routes.blogs.url, type: '' },
    {
      title: routes.executiveTeam.title,
      url: routes.executiveTeam.url,
      type: '',
    },
    {
      title: routes.jobPositions.title,
      url: routes.jobPositions.url,
      type: '',
    },
    { title: routes.aboutUs.title, url: routes.aboutUs.url, type: '' },
    { title: routes.contactUs.title, url: routes.contactUs.url, type: '' },
    { title: routes.faq.title, url: routes.faq.url, type: '' },
  ],
  StaticsDataTermsAndConditions: [
    {
      title: i18n.t('components.footer.termsAndConditions.educationalProgram'),
      url: routes.staticPages.href('curriculum'),
      type: '',
    },
    {
      title: i18n.t(
        'components.footer.termsAndConditions.behavioralGuidelines'
      ),
      url: routes.staticPages.href('behavioral_guidelines'),
      type: '',
    },
    {
      title: i18n.t('components.footer.termsAndConditions.networkMarketing'),
      url: 'https://easnaf.mimt.gov.ir',
      type: '',
    },
    {
      title: i18n.t('components.footer.termsAndConditions.networkNotification'),
      url: 'https://www.mlmbook.ir/',
      type: '',
    },

    {
      title: i18n.t('components.footer.termsAndConditions.agentsRules'),
      url: routes.staticPages.href('activity_regulations'),
      type: '',
    },
    {
      title: i18n.t('components.footer.termsAndConditions.monetizationProgram'),
      url: '',
      type: 'plans',
    },
    {
      title: i18n.t(
        'components.footer.termsAndConditions.networkMarketingRegulations'
      ),
      url: 'http://easnaf.ir/Files/PDF/ghanoon1.pdf',
      type: '',
    },
    {
      title: i18n.t(
        'components.footer.termsAndConditions.complaintRegistration'
      ),
      url: routes.complaints.url,
      type: '',
    },
  ],
};

export default footerLinks;

const totalBasketPrice = ({
  products,
}: {
  products: { payable_price: number; quantity: number }[];
}): number =>
  products.reduce(
    (acc, product) => product.payable_price * product.quantity + acc,
    0
  );

const totalPV = ({
  products,
}: {
  products: { pv: number; quantity: number }[];
}): number =>
  products.reduce((acc, product) => product.pv * product.quantity + acc, 0);

const totalCV = ({
  products,
}: {
  products: { cv: number; quantity: number }[];
}): number =>
  products.reduce((acc, product) => product.cv * product.quantity + acc, 0);

const productCount = ({
  products,
  code,
}: {
  products: { code: number; quantity: number }[];
  code: number;
}): number => products.find((product) => product.code === code)?.quantity || 0;

export { productCount, totalBasketPrice, totalCV, totalPV };

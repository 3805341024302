import React from 'react';
import Image from 'next/image';

import { Box, Link } from '@/components';
import Image1 from '@/images/footer/symbols/image1.jpg';
import Image2 from '@/images/footer/symbols/image2.png';
import Image3 from '@/images/footer/symbols/image3.png';
import marketerLogo from '@/images/footer/symbols/marketing-logo.png';

const DynamicSymbol: React.FC = () => {
  const dataLinks = [
    {
      item: (
        <img
          referrerPolicy="origin"
          id="nbqejzpejxlzjzpeesgtsizp"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            window.open(
              'https://logo.samandehi.ir/Verify.aspx?id=271709&p=uiwkjyoerfthjyoeobpdpfvl',
              'Popup',
              `toolbar=no, scrollbars=no, location=no, statusbar=no,
                   menubar=no, resizable=0, width=450, height=630, top=30`
            )
          }
          alt="logo-samandehi"
          src="https://logo.samandehi.ir/logo.aspx?id=271709&p=odrfyndtnbpdyndtlymabsiy"
        />
      ),
      image: Image1,
      link: 'https://logo.samandehi.ir/Verify.aspx?id=271709&p=uiwkjyoerfthjyoeobpdpfvl',
      id: 1,
    },
    {
      item: (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          referrerPolicy="origin"
          target="_blank"
          href="https://trustseal.enamad.ir/?id=139233&Code=skiTAaQhi03997IytzqP"
        >
          <img
            referrerPolicy="origin"
            src="https://trustseal.enamad.ir/logo.aspx?id=139233&Code=skiTAaQhi03997IytzqP"
            alt="enamd"
            style={{ cursor: 'pointer' }}
            id="skiTAaQhi03997IytzqP"
          />
        </a>
      ),
      image: Image2,
      link: 'https://trustseal.enamad.ir/?id=139233&Code=skiTAaQhi03997IytzqP',
      id: 2,
    },
    {
      item: (
        <a
          href="https://easnaf.mimt.gov.ir/fa/mim_bic_companies/43007/standalone/1/logo/1/شرکت.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            width="67"
            height="68"
            className="marketerLogo"
            src={marketerLogo}
            alt="نماد بازاریابی‌یابی"
          />
        </a>
      ),
      image: Image3,
      link: 'https://easnaf.mimt.gov.ir/fa/mim_bic_companies/43007/standalone/1/logo/1/شرکت.html',
      id: 3,
    },
  ];
  return (
    <>
      {dataLinks.map((data) => (
        <Box key={data.id} w={68} h={68}>
          {process.env.NODE_ENV === 'production' ? (
            data.item
          ) : (
            <Link href={data.link} target={'_blank'} prefetch={false}>
              <Image src={data.image} alt="image" width="68" height="68" />
            </Link>
          )}
        </Box>
      ))}
    </>
  );
};
export default DynamicSymbol;

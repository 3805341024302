import type { IHeaderMenu } from './data';

import React from 'react';
import { useParams, usePathname } from 'next/navigation';

import {
  BoardLinks,
  Box,
  Button,
  Container,
  DesktopCart,
  Divider,
  Flex,
  HamburgerMenu,
  HeaderProfile,
  HeaderSearch,
  HStack,
  Icon,
  Image,
  Link as CkLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from '@/components';
import { useCategories, useProfile, useTranslation } from '@/hooks';
import useBrands from '@/hooks/queries/useBrands';
import LiateamBirthdayLogo from '@/images/anniversary/lia-birthday.png';
import LiateamWeddingLogo from '@/images/anniversary/lia-wedding.png';
import { routes, toolsLinks } from '@/statics';
import ArrowDown from '@/svgs/ArrowDown.svg';
import Circle from '@/svgs/circle.svg';
import LiateamLogo from '@/svgs/liateam-logo.svg';

const HeaderDesktop: IHeaderMenu = () => {
  const { t } = useTranslation('components.header');
  const location = useParams();
  const pathname = usePathname();

  const { data: profile } = useProfile();

  const { data: categoriesData } = useCategories();
  const { data: brands } = useBrands();

  const categoriesTree = categoriesData
    ?.filter((item) => item.parent_code === null)
    .map((item) => ({
      ...item,
      subtitle: categoriesData.filter((el) => el.parent_code === item.code),
    }));

  const [subtitle, setSubtitle] = React.useState(categoriesTree?.[0].title);

  const internalLinks = [
    { title: routes.blogs.title, url: routes.blogs.url },
    { title: routes.executiveTeam.title, url: routes.executiveTeam.url },
    { title: routes.jobPositions.title, url: routes.jobPositions.url },
    { title: routes.aboutUs.title, url: routes.aboutUs.url },
    { title: routes.contactUs.title, url: routes.contactUs.url },
    { title: routes.faq.title, url: routes.faq.url },
  ];

  return (
    <Box background="white" zIndex="docked" position="relative">
      <Container display={{ md: 'block', base: 'none' }}>
        <Stack pt={3} pb={5}>
          <Flex
            gap={6}
            pb={4}
            borderBottomWidth={0.25}
            borderBottomColor="gray.300"
          >
            {toolsLinks.map((toolLink) => (
              <CkLink
                prefetch={false}
                target={'_blank'}
                fontSize="xs"
                color={
                  toolLink.type === 'highlighted' ? 'primary.500' : 'inherit'
                }
                pl={6}
                key={toolLink.url}
                href={toolLink.url || ''}
                borderLeftWidth={0.25}
                borderLeftColor="gray.300"
              >
                {toolLink.title}
              </CkLink>
            ))}
            <Menu>
              <MenuButton
                fontSize="xs"
                role="group"
                color="primary.500"
                as={Button}
                alignItems="center"
                display="inline-flex"
                p={0}
                height="18px"
                rightIcon={
                  <Flex alignItems="center">
                    <ArrowDown fill="text" />
                  </Flex>
                }
                fontWeight={500}
                variant="unstyled"
              >
                {t('liaBoards')}
              </MenuButton>
              <MenuList
                display="flex"
                borderRadius={4}
                zIndex="dropdown"
                pb={8}
                w="300px"
              >
                <BoardLinks />
              </MenuList>
            </Menu>
          </Flex>
          <Flex justify="space-between" align="center">
            <Flex align="center">
              <CkLink href="/">
                {profile?.is_birthdate_anniversary ? (
                  <Image
                    src={LiateamBirthdayLogo}
                    alt="liateam-logo"
                    width={70}
                  />
                ) : profile?.is_marriage_anniversary ? (
                  <Image
                    src={LiateamWeddingLogo}
                    alt="liateam-logo"
                    width={70}
                  />
                ) : (
                  <Icon
                    w={{
                      base: '45px',
                      md: '58px',
                      lg: '70px',
                    }}
                    h={{
                      base: '45px',
                      md: '58px',
                      lg: '70px',
                    }}
                    as={LiateamLogo}
                  />
                )}
              </CkLink>
              <Box display={{ base: 'none', md: 'block', xl: 'none' }}>
                <HamburgerMenu />
              </Box>

              <Box display={{ base: 'none', xl: 'flex' }}>
                <Flex align="center">
                  <CkLink
                    color={
                      pathname === routes.categories.url
                        ? 'primary.500'
                        : 'text'
                    }
                    fontSize="sm"
                    fontWeight={500}
                    pr={'32px'}
                    href={routes.categories.url}
                    height="min-content"
                    _hover={{ color: 'primary.500' }}
                  >
                    {t('shops')}
                  </CkLink>
                </Flex>
                <Menu onClose={() => setSubtitle(categoriesTree?.[0].title)}>
                  <MenuButton
                    role="group"
                    _hover={{
                      color: 'primary.500',
                    }}
                    as={Button}
                    alignItems={'center'}
                    display="inline-flex"
                    paddingInline={8}
                    color="text"
                    rightIcon={
                      <Flex
                        _groupHover={{
                          fill: 'primary.500',
                        }}
                        fill="text"
                        alignItems="center"
                      >
                        <ArrowDown fill={'text'} />
                      </Flex>
                    }
                    fontWeight={500}
                    fontSize="sm"
                    variant="unstyled"
                  >
                    {t('categories')}
                  </MenuButton>
                  <MenuList
                    margin={0}
                    padding={0}
                    display={'flex'}
                    borderRadius={'11px'}
                    zIndex={'dropdown'}
                    height={
                      categoriesTree && `${categoriesTree?.length * 45 + 50}px`
                    }
                    minHeight={'318px'}
                    w="100%"
                  >
                    <Box margin={0} padding={0} pt={'21px'}>
                      {categoriesTree?.map((item) => (
                        <MenuItem
                          as={CkLink}
                          key={item.id}
                          href={routes.category.href(item.code)}
                          paddingRight={'10px'}
                          w={'240px'}
                          mr={'36px'}
                          pt={'12px'}
                          pb={'13px'}
                          color={'text'}
                          fontSize={'sm'}
                          bgColor={
                            item.title === subtitle ? 'gray.300' : 'transparent'
                          }
                          cursor={'pointer'}
                          onMouseEnter={() => setSubtitle(item.title)}
                        >
                          <Circle />
                          <Box mr={'10px'}>{item.title}</Box>
                        </MenuItem>
                      ))}
                    </Box>

                    <Box
                      minHeight="318px"
                      height={
                        categoriesTree &&
                        `${categoriesTree?.length * 45 + 50}px`
                      }
                      paddingBlock="21px"
                    >
                      <Divider orientation="vertical" />
                    </Box>
                    <Stack
                      direction="column"
                      flexWrap="wrap"
                      margin={0}
                      padding={0}
                      pt={'21px'}
                      minWidth="446px"
                      gap="0"
                    >
                      {categoriesTree?.map(
                        (item) =>
                          item?.title === subtitle &&
                          item?.subtitle.map((sub) => (
                            <MenuItem
                              as={CkLink}
                              sx={
                                location.subcategory === sub.id
                                  ? { bg: 'primary.500', color: 'white' }
                                  : {}
                              }
                              href={routes.subCategory.href(
                                item.code,
                                sub.code
                              )}
                              key={sub.id}
                              paddingTop={'12px'}
                              paddingBottom={'13px'}
                              mr={'19px'}
                              w={'204px'}
                              color={'text'}
                              fontSize={'sm'}
                            >
                              {sub.title}
                            </MenuItem>
                          ))
                      )}
                    </Stack>
                    {categoriesTree?.map(
                      (item) =>
                        item?.title === subtitle && (
                          <Box
                            key={item.id}
                            width={'482px'}
                            height={'276px'}
                            margin={'21px 21px 21px 23px'}
                            display={'flex'}
                            alignItems={'center'}
                            sx={{
                              minW: 'max-content',
                              height: '276px',
                              img: {
                                width: '482px',
                                height: '276px',
                                objectFit: 'cover',
                              },
                            }}
                          >
                            <Image
                              src={item.banner_image || ''}
                              alt={subtitle}
                              width={482}
                              height={276}
                              style={{ borderRadius: '8px' }}
                            />
                          </Box>
                        )
                    )}
                  </MenuList>
                </Menu>
                {brands && brands?.length > 0 && (
                  <Menu>
                    <MenuButton
                      role="group"
                      _hover={{
                        color: 'primary.500',
                      }}
                      as={Button}
                      alignItems="center"
                      display="inline-flex"
                      pr={0}
                      pl={8}
                      color={
                        pathname.startsWith('/brands') ? 'primary.500' : 'text'
                      }
                      rightIcon={
                        <Flex
                          _groupHover={{
                            fill: 'primary.500',
                          }}
                          fill={
                            pathname.startsWith('/brands')
                              ? 'primary.500'
                              : 'text'
                          }
                          alignItems="center"
                        >
                          <ArrowDown
                            fill={
                              pathname.startsWith('/brands')
                                ? 'primary.500'
                                : 'text'
                            }
                          />
                        </Flex>
                      }
                      fontWeight={500}
                      fontSize="sm"
                      variant="unstyled"
                    >
                      {t('brands')}
                    </MenuButton>
                    <MenuList
                      m={0}
                      p={0}
                      display="flex"
                      borderRadius={4}
                      zIndex="dropdown"
                      overflow="hidden"
                      w="100%"
                    >
                      <Box>
                        {brands.map((item) => (
                          <MenuItem
                            as={CkLink}
                            key={item.code}
                            href={routes.brand.href(item.code)}
                            paddingRight="10px"
                            w="240px"
                            pt="12px"
                            pb="13px"
                            color="text"
                            fontSize="sm"
                            bgColor="transparent"
                            _hover={{ bgColor: 'gray.300' }}
                          >
                            <Circle />
                            <Box mr={'10px'}>{item.title}</Box>
                          </MenuItem>
                        ))}
                      </Box>
                    </MenuList>
                  </Menu>
                )}
                <Flex align="center">
                  {internalLinks.map((internalLink) => (
                    <CkLink
                      color={
                        pathname === internalLink.url ? 'primary.500' : 'text'
                      }
                      fontSize="sm"
                      fontWeight={500}
                      pl={'35px'}
                      key={internalLink.url}
                      href={internalLink.url}
                      height="min-content"
                      _hover={{ color: 'primary.500' }}
                    >
                      {internalLink.title}
                    </CkLink>
                  ))}
                </Flex>
              </Box>
            </Flex>
            <HStack alignItems={'center'}>
              <HeaderSearch />
              <Divider orientation="vertical" height="40px" />
              <DesktopCart />
              <HeaderProfile />
            </HStack>
          </Flex>
        </Stack>
      </Container>
    </Box>
  );
};

export default HeaderDesktop;

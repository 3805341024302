import type { IBaseProductCounter } from './types';

import React from 'react';
import { Minus, Plus, Trash2, X } from 'react-feather';

import { HStack, Icon, IconButton, Spinner, Stack, Text } from '@/components';
import { useTranslation } from '@/hooks';

const BaseProductCounter: IBaseProductCounter = ({
  loading = false,
  isZeroInventory,
  isLowInventory,
  isLimitedByPromotion,
  size = 'md',
  width = '100%',
  justify = 'center',
  count,
  limit,
  decrease,
  increase,
  remove,
}) => {
  const { t } = useTranslation('components.baseProductCounter');
  if (isZeroInventory) {
    return (
      <HStack justify="center">
        <Icon
          as={X}
          w={5}
          h={5}
          size="lg"
          strokeWidth="3px"
          p={0.5}
          color="white"
          bg="red.500"
          borderRadius="50%"
        />
        <Text color="red.500" fontSize={{ md: size === 'md' ? 'lg' : 'unset' }}>
          {t('becameOutOfStock')}
        </Text>
      </HStack>
    );
  }
  return (
    <Stack
      direction={{ base: 'row-reverse', md: 'column' }}
      justify={justify}
      align="center"
    >
      <HStack
        w={width}
        justify="space-between"
        border="1px"
        borderColor={{ base: 'text', md: 'gray.800' }}
        borderRadius="curved"
        paddingInline={size === 'sm' ? 0 : 3}
        paddingBlock={{ base: 0.75, md: size === 'md' ? 2 : 0.75 }}
      >
        <IconButton
          aria-label="increase count"
          colorScheme="unstyled"
          color={{ base: 'text', md: 'gray.800' }}
          icon={<Icon as={Plus} />}
          isDisabled={(limit !== undefined && count >= limit) || loading}
          onClick={(e) => {
            e.stopPropagation();
            void increase();
          }}
        />
        <Text
          color={{ base: 'text', md: 'gray.800' }}
          lineHeight={{ md: size === 'sm' ? '33px' : 'unset' }}
          fontSize={{ md: '3xl' }}
        >
          {loading ? <Spinner h="20px" w="20px" /> : count}
        </Text>
        <IconButton
          aria-label="decrease count"
          colorScheme="unstyled"
          color={{ base: 'text', md: 'gray.800' }}
          icon={<Icon as={count === 1 && remove ? Trash2 : Minus} />}
          isDisabled={count === 0 || loading}
          onClick={(e) => {
            e.stopPropagation();
            if (count === 0) {
              return;
            }
            if (count === 1 && remove) {
              remove();
              return;
            }
            decrease();
          }}
        />
      </HStack>
      {isLowInventory && (
        <Text color="red.500" fontSize={{ md: 'md' }} whiteSpace="nowrap">
          {t('lowInventory')}
        </Text>
      )}
      {isLimitedByPromotion && (
        <Text color="red.500" fontSize={{ md: 'md' }} whiteSpace="nowrap">
          {t('isLimitedByPromotion')}
        </Text>
      )}
    </Stack>
  );
};

export default BaseProductCounter;

import type { ICartProductCount } from './types';

import React from 'react';
import { useShallow } from 'zustand/react/shallow';

import { BaseProductCounter } from '@/components';
import { useBasketStore } from '@/hooks';
import { productCount } from '@/utils';
import { googleEventRemoveFromCart } from '@/utils/googleAnalyticsEvent';

const CartProductCount: ICartProductCount = ({
  isZeroInventory,
  isLowInventory,
  isLimitedByPromotion,
  product,
  size = 'md',
}) => {
  const [count, decrease, increase, remove] = useBasketStore(
    useShallow((state) => [
      productCount({ products: state.products, code: product.code }),
      state.decrease,
      state.increase,
      state.remove,
    ])
  );
  return (
    <BaseProductCounter
      count={count}
      decrease={() => decrease({ code: product.code })}
      increase={() => increase({ code: product.code })}
      remove={() => {
        googleEventRemoveFromCart(product);
        remove({ code: product.code });
      }}
      isLowInventory={isLowInventory}
      isZeroInventory={isZeroInventory}
      isLimitedByPromotion={isLimitedByPromotion}
      limit={product.limit_buy_inventory}
      size={size}
    />
  );
};

export default CartProductCount;

'use client';

import type { Plans, SocialNetworks } from '@/types';

import React from 'react';

import '@/i18n';

import { Box, Footer, Header } from '@/components';
import { useProfile } from '@/hooks';

export default function PublicWrapper({
  children,
  plans,
  socialNetworks,
}: {
  children: React.ReactNode;
  plans: Plans;
  socialNetworks: SocialNetworks;
}): React.ReactElement {
  const { data: profileUser } = useProfile();

  return (
    <>
      <Box
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        background-position="center"
        filter={profileUser?.is_suspended ? 'grayscale(1)' : 'unset'}
      >
        <Box flex="1">
          <Header />
          {children}
        </Box>
        {plans && socialNetworks && (
          <Footer plans={plans.plan_file} socialNetworks={socialNetworks} />
        )}
      </Box>
    </>
  );
}

import React from 'react';

import DynamicSymbol from '../DynamicSymbol';

import { Box, SimpleGrid } from '@/components';

const Symbols: React.FC = () => {
  return (
    <Box mt={{ xl: 42 }}>
      <SimpleGrid alignItems="start" columns={{ base: 3, xl: 2 }} spacing={3}>
        <DynamicSymbol />
      </SimpleGrid>
    </Box>
  );
};

export default Symbols;

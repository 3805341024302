import type { IHeaderBasket } from './data';

import { useShallow } from 'zustand/react/shallow';

import { Button, Circle, Icon, Text } from '@/components';
import { useBasketStore } from '@/hooks';
import Basket from '@/svgs/header/basket.svg';

const HeaderBasket: IHeaderBasket = ({ onClick }) => {
  const basketCount = useBasketStore(
    useShallow((basket) =>
      basket.products.reduce(
        (acc, cartProduct) => acc + cartProduct.quantity,
        0
      )
    )
  );
  return (
    <Button
      variant="unstyled"
      position="relative"
      onClick={() => (onClick ? onClick() : null)}
      paddingInline={0}
      display="flex"
      justifyContent={{ base: 'flex-end', md: 'center' }}
      aria-label="basket"
    >
      <Icon as={Basket} w={6} h={6} />
      {basketCount > 0 && (
        <Circle
          position="absolute"
          size="27px"
          top={{ base: '-6px', md: '-9px' }}
          right={{ base: 0.5, md: -1 }}
          bg="primary.500"
          ml={3}
          minWidth="fit-content"
          paddingInline={1}
        >
          <Text color="white" fontSize="lg" mt={1}>
            {basketCount}
          </Text>
        </Circle>
      )}
    </Button>
  );
};

export default HeaderBasket;

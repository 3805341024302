import type { SocialNetworks } from '@/types';

import React from 'react';

import {
  Box,
  Flex,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@/components';
import { useTranslation } from '@/hooks';

const SocialMediaMobile: React.FC<{ socialNetworks: SocialNetworks }> = ({
  socialNetworks,
}) => {
  const { t } = useTranslation('components.footer');

  return (
    <>
      <Flex direction="column">
        <SimpleGrid
          mb={6}
          mt={6}
          alignItems={'center'}
          justifyContent={'center'}
          autoColumns="min-content"
          columns={{ base: 1, sm: 2 }}
          spacingY="10"
        >
          <Box>
            <VStack alignItems={'center'} justifyContent={'center'}>
              <Text color="text" fontSize="xs" textAlign={'center'}>
                {t('liateam')}
              </Text>
            </VStack>
          </Box>
          <Box>
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              direction={'row'}
              color="asphalt.500"
              fontSize="sm"
              spacing="14px"
            >
              {socialNetworks.map((item) => (
                <Link
                  prefetch={false}
                  href={item.link}
                  key={item.id}
                  target={'_blank'}
                >
                  <Box
                    backgroundColor="gray.700"
                    borderRadius="50%"
                    padding="7px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      src={item.icon}
                      alt={item.alt_icon}
                      width={20}
                      height={20}
                    />
                  </Box>
                </Link>
              ))}
            </Stack>
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
};

export default SocialMediaMobile;

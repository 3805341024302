import Link from 'next/link';

import { Button, Circle } from '@/components';
import { usePopups, useProfile } from '@/hooks';
import { routes } from '@/statics';
import NotificationIcon from '@/svgs/dashboard/drawer/notification.svg';

const MobileNotification: React.FC = () => {
  const { data: profile } = useProfile();
  const { data } = usePopups({ profile: Boolean(profile), isRead: false });

  return (
    <Button
      variant="ghost"
      padding={0}
      position="relative"
      as={Link}
      href={routes.notifications.url}
    >
      {data?.list && data.list.length > 0 && (
        <Circle
          position="absolute"
          bg="primary.500"
          size="8px"
          top={'7px'}
          right={'10px'}
        ></Circle>
      )}
      <NotificationIcon stroke="black" strokeWidth="1.5" />
    </Button>
  );
};
export default MobileNotification;

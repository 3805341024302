import React from 'react';

import { Box, Stack, Symbols, Text } from '@/components';
import { useTranslation } from '@/hooks';

const InspectionNumberDesktop: React.FC = () => {
  const { t } = useTranslation('pages.footer');

  return (
    <>
      <Stack
        h={'110'}
        direction={{ base: 'column', md: 'row' }}
        justifyContent={'center'}
        alignItems={'center'}
        mt={{ base: 10, xl: 0 }}
        mb={{ base: 10, xl: 0 }}
      >
        <Text
          mb={{ base: '25px', md: 0 }}
          textAlign={'center'}
          color="text"
          fontSize={{ base: 'xs', xl: 'sm' }}
        >
          {t('disclaimer')}
        </Text>
        <Box display={{ base: 'block', xl: 'none' }} mb={'26px'}>
          <Symbols />
        </Box>
      </Stack>
    </>
  );
};

export default InspectionNumberDesktop;

import type { LocalBasketProduct, ProductGroup } from '@/types';

import type {} from '@redux-devtools/extension';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type BasketState = {
  userType: 'agent' | 'customer' | null;
  products: LocalBasketProduct[];
  add: ({
    product,
    count,
  }: {
    product: ProductGroup;
    count?: number;
    exam_id?: string;
  }) => void;
  increase: ({ code, count }: { code: number; count?: number }) => void;
  decrease: ({ code, count }: { code: number; count?: number }) => void;
  setInventoryLimit: ({
    code,
    inventoryLimit,
  }: {
    code: number;
    inventoryLimit: number;
  }) => void;
  changePrice: ({
    code,
    newPayablePrice,
    newPrice,
    pv,
    cv,
  }: {
    code: number;
    newPrice: number;
    newPayablePrice: number;
    pv: number;
    cv: number;
  }) => void;
  remove: ({ code }: { code: number }) => void;
  empty: () => void;
};

const useBasketStore = create<BasketState>()(
  devtools(
    persist(
      immer((set) => ({
        userType: null,
        products: [],
        add: ({ product, count = 1, exam_id = null }) =>
          set(
            (state) => {
              const foundProduct = state.products.find(
                (item) => item.code === product.code
              );
              if (foundProduct) {
                state.increase({ code: product.code, count });
              } else {
                const { pricing } = product;
                state.products.push({
                  id: product.id,
                  code: product.code,
                  title: product.title,
                  category_code: product.category_code,
                  home_page_image: product.home_page_image,
                  price: product.price,
                  limit_buy_inventory: product.inventory.limit_buy_inventory,
                  payable_price: pricing
                    ? pricing.payable_price
                    : product.price,
                  quantity: count,
                  pv: pricing ? pricing.pv : 0,
                  cv: pricing ? pricing.cv : 0,
                  exam_id,
                });
              }
            },
            false,
            { type: 'basket/add' }
          ),
        increase: ({ code, count = 1 }) =>
          set(
            (state) => {
              const foundProduct = state.products.find(
                (item) => item.code === code
              );
              if (foundProduct) {
                if (
                  foundProduct.quantity + count <=
                  foundProduct.limit_buy_inventory
                ) {
                  foundProduct.quantity += count;
                }
              }
            },
            false,
            { type: 'basket/increase' }
          ),
        setInventoryLimit: ({ code, inventoryLimit }) =>
          set(
            (state) => {
              const foundProduct = state.products.find(
                (item) => item.code === code
              );
              if (foundProduct) {
                foundProduct.limit_buy_inventory = inventoryLimit;
                if (foundProduct.quantity >= inventoryLimit) {
                  foundProduct.quantity = inventoryLimit;
                }
              }
            },
            false,
            { type: 'basket/setInventoryLimit' }
          ),
        changePrice: ({ code, newPrice, newPayablePrice, pv, cv }) =>
          set(
            (state) => {
              const foundProduct = state.products.find(
                (item) => item.code === code
              );
              if (foundProduct) {
                foundProduct.price = newPrice;
                foundProduct.payable_price = newPayablePrice;
                foundProduct.pv = pv;
                foundProduct.cv = cv;
              }
            },
            false,
            { type: 'basket/changePrice' }
          ),
        decrease: ({ code, count = 1 }) =>
          set(
            (state) => {
              const foundProduct = state.products.find(
                (item) => item.code === code
              );
              if (foundProduct) {
                const newQuantity = foundProduct.quantity - count;
                if (newQuantity <= 0) {
                  const foundProductIndex = state.products.findIndex(
                    (item) => item.code === code
                  );
                  state.products.splice(foundProductIndex, 1);
                } else {
                  foundProduct.quantity = newQuantity;
                }
              }
            },
            false,
            { type: 'basket/decrease' }
          ),
        remove: ({ code }) =>
          set(
            (state) => {
              const foundProductIndex = state.products.findIndex(
                (item) => item.code === code
              );
              state.products.splice(foundProductIndex, 1);
            },
            true,
            { type: 'basket/remove' }
          ),
        empty: () =>
          set(
            (state) => {
              state.products = [];
            },
            true,
            { type: 'basket/empty' }
          ),
      })),
      {
        name: 'basket-storage',
      }
    ),
    { enabled: process.env.NODE_ENV === 'development' }
  )
);

export default useBasketStore;

import type { ICartProduct } from './types';

import React from 'react';
import { XCircle } from 'react-feather';
import { useShallow } from 'zustand/react/shallow';

import {
  Box,
  CartProductCount,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Text,
  VStack,
} from '@/components';
import { useBasketStore } from '@/hooks';
import { routes } from '@/statics';
import { toToman } from '@/utils';
import { googleEventRemoveFromCart } from '@/utils/googleAnalyticsEvent';

const CartProduct: ICartProduct = ({ product, afterClick }) => {
  const remove = useBasketStore(useShallow((state) => state.remove));

  return (
    <VStack
      px={4}
      py="2"
      align="flex-start"
      gap={1}
      w="100%"
      position="relative"
    >
      <IconButton
        variant="ghost"
        aria-label="Remove product from basket"
        position="absolute"
        colorScheme="gray"
        size="xl"
        top={-1}
        right={1}
        icon={<Icon as={XCircle} w={6} h={6} color="gray.700" />}
        onClick={(e) => {
          e.stopPropagation();
          googleEventRemoveFromCart(product);
          remove({ code: product.code });
          e.currentTarget.blur();
        }}
      />
      <Link
        w="100%"
        _hover={{ cursor: 'pointer' }}
        href={routes.product.href(product.code)}
        onClick={() => afterClick?.()}
      >
        <HStack align="flex-start" alignItems="stretch">
          <Box minW="96px" minH="96px" borderRadius="sm" bg="gray.300">
            <Image
              width={96}
              height={96}
              src={product.home_page_image}
              alt={product.title}
            />
          </Box>
          <VStack align="flex-start" w="100%" justify="space-between">
            <Text
              fontSize={{ base: 'xs', md: 'sm' }}
              textAlign="start"
              whiteSpace="pre-line"
              textOverflow="ellipsis"
              noOfLines={2}
              lineHeight={{ base: '24px', md: '33px' }}
            >
              {product.title}
            </Text>
            <HStack
              justify="space-between"
              w="100%"
              align="center"
              h={{ base: '33.5px', md: '36.5px' }}
            >
              <Text fontSize={{ base: 'xs', md: 'sm' }} color="gray.700">
                {toToman(product.payable_price)}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </Link>
      <Box w="96px" position="absolute" left={4} bottom={2}>
        <CartProductCount product={product} size="sm" />
      </Box>
    </VStack>
  );
};

export default CartProduct;

import type { IDesktopCart } from './types';

import React from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  Box,
  Button,
  CartProduct,
  HeaderBasket,
  HStack,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
} from '@/components';
import {
  useBasketStore,
  useDisclosure,
  useOutsideClick,
  useProfile,
  useTranslation,
} from '@/hooks';
import { routes } from '@/statics';
import {
  separator,
  totalBasketPrice,
  totalCV,
  totalPV,
  toToman,
} from '@/utils';
import { googleEventViewCart } from '@/utils/googleAnalyticsEvent';

const DesktopCart: IDesktopCart = () => {
  const { t } = useTranslation('components.header.cart');
  const { data: profile } = useProfile();
  const products = useBasketStore(useShallow((state) => state.products));
  const { isOpen, onToggle, onClose } = useDisclosure();
  const ref = React.useRef<HTMLDivElement | null>(
    null
  ) as React.RefObject<HTMLDivElement>;
  useOutsideClick({
    ref,
    handler: onClose,
  });

  return (
    <Box>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        closeOnBlur={false}
        isLazy
        arrowSize={12}
      >
        <PopoverTrigger>
          <Box cursor="pointer">
            <HeaderBasket
              onClick={() => {
                onToggle();
                googleEventViewCart(products, totalBasketPrice({ products }));
              }}
            />
          </Box>
        </PopoverTrigger>
        <Portal>
          <Box zIndex="popover" ref={ref} w="full" h="full">
            <PopoverContent
              zIndex="popover"
              maxH="60dvh"
              w="368px"
              boxShadow="elevated1"
            >
              <PopoverArrow />
              <PopoverBody
                maxH="100%"
                overflowY={products.length > 0 ? 'scroll' : 'unset'}
                borderRadius="sm"
              >
                <VStack>
                  {products.length === 0 && (
                    <VStack w="full" py={10}>
                      <Text fontSize="sm" color="gray.800">
                        {t('yourCartIsEmpty')}
                      </Text>
                    </VStack>
                  )}
                  {products.map((product) => (
                    <CartProduct
                      afterClick={onClose}
                      key={product.id}
                      product={product}
                    />
                  ))}
                  {profile?.user_type === 'agent' && products.length > 0 && (
                    <HStack justify="space-between" w="100%" paddingInline={4}>
                      <Text dir="ltr">
                        {t('cv')}: {separator(totalCV({ products }))}
                      </Text>
                      <Text dir="ltr">
                        {t('pv')}: {separator(totalPV({ products }))}
                      </Text>
                    </HStack>
                  )}
                </VStack>
              </PopoverBody>
              {products.length > 0 && (
                <PopoverFooter
                  marginInline={7}
                  px={0}
                  paddingTop={4}
                  paddingBottom={5}
                >
                  <HStack
                    direction="row"
                    align="center"
                    justify="space-between"
                  >
                    <VStack gap="1" align="flex-start">
                      <Text fontWeight="medium" color="gray.700">
                        {t('total')}:
                      </Text>
                      <Text fontWeight="medium" color="black">
                        {toToman(totalBasketPrice({ products }))}
                      </Text>
                    </VStack>
                    <Button
                      onClick={onClose}
                      colorScheme="secondary"
                      position="relative"
                      as={Link}
                      href={routes.checkout.url}
                    >
                      {t('submitOrder')}
                    </Button>
                  </HStack>
                </PopoverFooter>
              )}
            </PopoverContent>
          </Box>
        </Portal>
      </Popover>
    </Box>
  );
};

export default DesktopCart;

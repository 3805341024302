import type { IErrorResponse, ProductGroup } from '@/types';
import type { PaginationResponse } from '@/types/PaginationResponse';

import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import queryString from 'query-string';

import { urls } from '@/statics';

const useSearch = (queries: {
  fuzzy_search: string;
  pageSize: number;
}): UseQueryResult<PaginationResponse<ProductGroup>, IErrorResponse> =>
  useQuery({
    queryKey: [`${urls.productGroups}?${queryString.stringify(queries)}`],
    enabled: Boolean(queries.fuzzy_search),
  });

export default useSearch;

import type { IHeaderProfile } from './data';

import React from 'react';
import { Circle, useDisclosure } from '@chakra-ui/react';
import { usePathname, useRouter } from 'next/navigation';

import {
  Box,
  Button,
  DashboardSideSheet,
  Icon,
  IconButton,
  Link,
  LogoutModal,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  PromotionCodeActivationModal,
  Text,
} from '@/components';
import { usePopups, useProfile, useTranslation } from '@/hooks';
import { routes } from '@/statics';
import AvatarIcon from '@/svgs/account.svg';

const HeaderProfile: IHeaderProfile = () => {
  const router = useRouter();
  const pathName = usePathname();

  const { data: profile } = useProfile();
  const { data: notification } = usePopups({
    profile: Boolean(profile),
    isRead: false,
  });
  const isAuthenticated = Boolean(profile);
  const isAgent = profile?.user_type === 'agent';
  const isCustomer = profile?.user_type === 'customer';

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenPromoCode,
    // onOpen: onOpenPromoCode,
    onClose: onClosePromoCode,
  } = useDisclosure();
  const { t } = useTranslation('components.header');
  type IMenuItem = {
    title: string | React.JSX.Element;
    href: string;
    hide?: boolean;
    rightElement?: React.ReactElement;
  };

  const notLoggedInMenuItems: IMenuItem[] = [
    {
      title: t('loginToAccount'),
      href: routes.signIn.url,
    },
    {
      title: t('customerSignUp'),
      href: `${routes.signUp.url}?type=customer`,
    },
    {
      title: t('agentSignUp'),
      href: `${routes.signUp.url}?type=agent`,
    },
  ];

  const loggedInMenuItems: IMenuItem[] = [
    {
      title: t('dashboard'),
      href: routes.dashboard.url,
    },
    // {
    //   title: <Box onClick={() => onOpenPromoCode()}>{t('promotionCode')}</Box>,
    //   href: '',
    // },
    {
      title: t('purchaseRecords'),
      href: routes.orders.url,
    },
    {
      title: t('favorites'),
      href: routes.favorites.url,
    },
    {
      title: t('notifications'),
      href: routes.notifications.url,
      rightElement:
        notification?.list && notification.list.length > 0 ? (
          <Circle size="30px" bg={'primary.500'} ml={3}>
            <Text color="white" marginTop={'4px'}>
              {notification.list.length}
            </Text>
          </Circle>
        ) : (
          <></>
        ),
    },
    {
      title: t('becomeAgent'),
      href: routes.identityBecomeAgent.url,
      hide: isAgent,
    },
  ];

  const menuItems = {
    loggedInMenuItems,
    notLoggedInMenuItems,
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.gtag) {
      if (profile) {
        window.gtag('set', 'user_id', profile?.public_id);
      }
    }
  }, []);

  return (
    <Box
      position="relative"
      w={{ base: 73, md: 'max-content' }}
      textAlign="left"
    >
      <IconButton
        colorScheme={
          (isAgent && 'tertiary') || (isCustomer && 'secondary') || 'primary'
        }
        w={'2rem'}
        h={'2rem'}
        borderRadius={'50%'}
        aria-label="Profile icon"
        icon={<AvatarIcon width={'23px'} height={'23px'} />}
        onClick={() => {
          if (profile) {
            onOpen();
            return;
          }
          router.push(`${routes.signIn.url}?returnURL=${pathName}`);
        }}
        display={{ base: 'inline-flex', md: 'none' }}
      />
      <DashboardSideSheet onClose={onClose} isOpen={isOpen} />
      {isOpenPromoCode && (
        <PromotionCodeActivationModal
          isOpen={isOpenPromoCode}
          onClose={onClosePromoCode}
        />
      )}
      <Box position="relative">
        {notification?.list && notification.list.length > 0 && (
          <Circle
            display={{ base: 'none', md: 'flex' }}
            alignItems="center"
            zIndex={1150}
            position="absolute"
            top="-15px"
            size="30px"
            border="1px solid"
            borderColor="primary.500"
            backgroundColor="#F2F2F2"
            color="black"
            fontWeight="bold"
            fontSize="md"
          >
            <Text height="max-content" marginTop={'4px'}>
              {notification.list.length}
            </Text>
          </Circle>
        )}
        <Menu strategy="absolute" placement="bottom">
          <MenuButton
            colorScheme={
              (isAgent && 'tertiary') ||
              (isCustomer && 'secondary') ||
              'primary'
            }
            mr={2}
            display={{ base: 'none', md: 'inline-flex' }}
            as={Button}
            size="sm"
            rightIcon={<Icon w={6} h={6} as={AvatarIcon} />}
            w="146px"
            zIndex="sticky"
          >
            {t('account')}
          </MenuButton>
          <MenuList
            w="146px"
            paddingInline="13px"
            pt="50px"
            pb={4}
            rootProps={{
              insetBlockStart: '-50px !important',
            }}
            zIndex="dropdown"
            minW="unset"
            borderRadius="22px"
            borderTop="none"
          >
            {menuItems[profile ? 'loggedInMenuItems' : 'notLoggedInMenuItems']
              .filter((item) => !item.hide)
              .map((item, index) => (
                <React.Fragment key={index}>
                  <MenuItem
                    display="flex"
                    justifyContent="center"
                    p={0}
                    as={Link}
                    href={item.href}
                  >
                    {item.rightElement}
                    <Text paddingBlock={2.5} fontSize="sm" textAlign="center">
                      {item.title}
                    </Text>
                  </MenuItem>
                  <MenuDivider _last={{ display: 'none' }} w="100%" />
                </React.Fragment>
              ))}

            {isAuthenticated && (
              <>
                <MenuDivider _last={{ display: 'none' }} w="100%" />
                <LogoutModal icon={false} />
              </>
            )}
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
};
export default HeaderProfile;

import { isClient } from '@/utils';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Navigator {
    msMaxTouchPoints: number;
  }
}
const useIsMobile = (): boolean => {
  if (isClient()) {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const [bodyElement] = document.getElementsByTagName('body');

    const isUserAgentMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    const isScreenResolutionMobile = screenWidth < 768 || screenHeight < 768;
    const isTouchEventsMobile =
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;
    const isCssMediaQueryMobile = window
      .getComputedStyle(bodyElement)
      .getPropertyValue('content')
      .includes('mobile');
    return (
      isUserAgentMobile ||
      isScreenResolutionMobile ||
      isTouchEventsMobile ||
      isCssMediaQueryMobile
    );
  }
  return false;
};

export default useIsMobile;

import type { IErrorResponse, IProductCategory } from '@/types';

import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { urls } from '@/statics';

const useCategories = (): UseQueryResult<
  IProductCategory[],
  IErrorResponse
> => {
  return useQuery({
    queryKey: [urls.categories],
    staleTime: Infinity,
    gcTime: Infinity,
  });
};

export default useCategories;

import type { FooterProps } from './data';

import InspectionNumberDesktop from './InspectionNumberDesktop';
import RightsDesktop from './RightsDesktop';
import SocialMediaMobile from './SocialMediaMobile';
import {
  ContactLia,
  Products,
  Symbols,
  TermsAndConditions,
  Tools,
} from './TitlesDesktop';
import TitlesPartMobile from './TitlesMobile';
import TopSection from './TopSection';

import { Box, Container, Divider } from '@/components';
import { useCategories } from '@/hooks';

const Footer: FooterProps = ({ socialNetworks, plans, ...props }) => {
  const { data: categoriesData } = useCategories();
  const rootCategories = categoriesData?.filter(
    (item) => item.parent_code === null
  );
  return (
    <Box {...props} width="100%" overflow="hidden">
      <footer>
        <Container mt={6}>
          <Box>
            <Divider color="gray.700" />
            <TopSection socialNetworks={socialNetworks} />
            <Box
              paddingInline="10px"
              flexDir={'row'}
              mt={'42'}
              mb={'36px'}
              display={{ base: 'none', xl: 'flex' }}
              justifyContent="space-between"
            >
              <Products categories={rootCategories || []} />
              <ContactLia />
              <Tools />
              {plans && <TermsAndConditions plansFile={plans} />}
              <Symbols />
            </Box>
            <Box display={{ base: 'block', xl: 'none' }}>
              {plans && (
                <TitlesPartMobile
                  plansFile={plans}
                  categories={rootCategories || []}
                />
              )}
            </Box>
            <Box display={{ base: 'block', xl: 'none' }}>
              <SocialMediaMobile socialNetworks={socialNetworks} />
            </Box>
            <Divider color="gray.700" />
            <Box mt={{ base: '30%', sm: '10%', md: 0 }}>
              <InspectionNumberDesktop />
            </Box>
          </Box>
        </Container>
        <RightsDesktop />
      </footer>
    </Box>
  );
};

export default Footer;

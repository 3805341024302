import React from 'react';

import { Stack, Text } from '@/components';
import { useTranslation } from '@/hooks';
import { jalaliDayjs } from '@/utils';

const RightsDesktop: React.FC = () => {
  const { t } = useTranslation('components.footer');
  const currentYear = jalaliDayjs().format('YYYY');

  return (
    <>
      <Stack
        gap={1}
        backgroundColor={'text'}
        h={'78'}
        direction={{ base: 'column', sm: 'row' }}
        justifyContent={'center'}
        textAlign={'center'}
        alignItems={'center'}
        padding={'23px 32px 24px 32px'}
      >
        <Text color="gray.600" fontSize="xs" textAlign={'center'}>
          {t('allRightsReserved', { currentYear })}
        </Text>
      </Stack>
    </>
  );
};

export default RightsDesktop;

import type { IHamburgerMenu } from './data';

import React from 'react';
import { X } from 'react-feather';

import {
  BoardLinks,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  HStack,
  IconButton,
  Link,
  Text,
} from '@/components';
import { useCategories, useDisclosure, useTranslation } from '@/hooks';
import useBrands from '@/hooks/queries/useBrands';
import { routes, toolsLinks } from '@/statics';
import LogoLia from '@/svgs/liateam-logo.svg';
import HamburgerMenuIcon from '@/svgs/responsive/hamburgerMenu.svg';
import PinkArrow from '@/svgs/responsive/pinkArrow.svg';
import PinkArrowRight from '@/svgs/responsive/pinkArrowRight.svg';

const HamburgerMenu: IHamburgerMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [flag, setFlag] = React.useState(false);
  const [arrayIndex, setArrayIndex] = React.useState(-1);
  const { t } = useTranslation('components.header');

  const { data: categoriesData } = useCategories();
  const { data: brands } = useBrands();
  const rootCategories = categoriesData?.filter(
    (item) => item.parent_code === null
  );

  const labelCategory = [
    {
      title: 'دسته‌بندی‌ها',
      id: 1,
      flag: flag,
      subtitle:
        rootCategories?.map((item) => ({
          title: item.title,
          url: routes.category.href(item.code),
        })) || [],
    },
    {
      id: 2,
      title: 'برندها',
      flag: flag,
      subtitle:
        brands?.map((item) => ({
          title: item.title,
          url: routes.brand.href(item.code),
        })) || [],
    },
    {
      id: 3,
      title: 'ارتباط با لیا',
      flag: flag,

      subtitle: [
        { title: routes.blogs.title, url: routes.blogs.url },
        { title: routes.executiveTeam.title, url: routes.executiveTeam.url },
        { title: routes.jobPositions.title, url: routes.jobPositions.url },
        { title: routes.aboutUs.title, url: routes.aboutUs.url },
        { title: routes.contactUs.title, url: routes.contactUs.url },
        { title: routes.faq.title, url: routes.faq.url },
      ],
    },
    {
      id: 4,
      title: 'ابزارها',
      flag: flag,
      subtitle: toolsLinks,
    },
  ];
  const handleButtonClick: (id: number) => void = (id) => {
    setFlag((prev) => !prev);
    setArrayIndex(id);
  };

  const handleClose = () => {
    setFlag(false);
    setArrayIndex(-1);
    onClose();
  };
  const handleBackButton = () => {
    setFlag((prev) => !prev);
    setArrayIndex(-1);
  };

  return (
    <>
      <HStack
        as="button"
        onClick={onOpen}
        gap={0}
        borderRadius={50}
        borderColor="primary.600"
        borderWidth={1}
        px={2}
        pl={3}
      >
        <HamburgerMenuIcon />
        <Text color="primary.600">{t('hamburgerMenu')}</Text>
      </HStack>

      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size={'full'}>
        <DrawerOverlay />
        <DrawerContent overflow="scroll">
          <Box>
            <Box h="50px" m={4} mt={2}>
              <HStack justifyContent="space-between">
                <Box></Box>
                <IconButton
                  _hover={{ bgColor: 'transparent' }}
                  aria-label="Logo Lia"
                  bgColor={'transparent'}
                  onClick={handleClose}
                  icon={
                    <Link href="/">
                      <Box
                        style={{ paddingRight: 0, border: 0 }}
                        height={45}
                        width={45}
                      >
                        <LogoLia />
                      </Box>
                    </Link>
                  }
                />
                <Button
                  p={0}
                  border={0}
                  onClick={handleClose}
                  rightIcon={<X size="24" />}
                  size="sm"
                  variant="unstyled"
                />
              </HStack>
            </Box>
            {!flag && arrayIndex === -1 ? (
              <>
                <Box>
                  <Link
                    href={routes.categories.url}
                    key={routes.categories.title}
                    onClick={onClose}
                  >
                    <Text px={'20px'} py={'17px'}>
                      {t('shops')}
                    </Text>
                  </Link>
                  <Box mr={'20px'} ml={'20px'}>
                    <Divider
                      borderWidth={'1px'}
                      borderColor={'primary.500'}
                      variant={'dashed'}
                    />
                  </Box>
                </Box>
                {labelCategory.map((item) => (
                  <Box key={item?.id}>
                    <HStack
                      cursor="pointer"
                      justify={'space-between'}
                      px={5}
                      onClick={() => handleButtonClick(item?.id)}
                    >
                      <Text py={'17px'}>{item?.title}</Text>
                      <Box>
                        <Box
                          style={{
                            padding: '17px 0',
                          }}
                        >
                          <PinkArrow />
                        </Box>
                      </Box>
                    </HStack>
                    <Box mr={'20px'} ml={'20px'}>
                      <Divider
                        borderWidth={'1px'}
                        borderColor={'primary.500'}
                        variant={'dashed'}
                      />
                    </Box>
                  </Box>
                ))}
              </>
            ) : (
              <>
                <HStack justify={'space-between'} px={5}>
                  <Box onClick={handleBackButton}>
                    <Box
                      cursor="pointer"
                      style={{
                        padding: '17px 0',
                      }}
                    >
                      <PinkArrowRight />
                    </Box>
                  </Box>
                  <Text py={'17px'}>
                    {labelCategory[arrayIndex - 1]?.title}
                  </Text>
                  <Box></Box>
                </HStack>
                <Box mr={'20px'} ml={'20px'}>
                  <Divider
                    borderWidth={'1px'}
                    borderColor={'primary.500'}
                    variant={'dashed'}
                  />
                </Box>
                {arrayIndex !== -1 &&
                  labelCategory[arrayIndex - 1]?.subtitle.map((item) => (
                    <Link href={item?.url} key={item?.title} onClick={onClose}>
                      <Text px={'40px'} py={'17px'}>
                        {item?.title}
                      </Text>
                    </Link>
                  ))}
              </>
            )}
          </Box>
          <BoardLinks />
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default HamburgerMenu;

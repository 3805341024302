import React from 'react';

import { Divider, Link, Text, VStack } from '@/components';
import { useTranslation } from '@/hooks';
import { footerLinks } from '@/statics';

const ContactLia: React.FC = () => {
  const { t } = useTranslation('components.footer.contactLia');

  return (
    <>
      <VStack alignItems={'start'}>
        <Text color="text" fontWeight="medium" fontSize={'sm'}>
          {t('contactLia')}
        </Text>
        <Divider w="100px" color={'gray.700'} borderWidth={0.2} />
        <VStack
          alignItems={'start'}
          spacing={'20px'}
          mt={{ base: 0, md: '0.5rem' }}
        >
          {footerLinks.StaticsDataContactLia.map((data, index: number) => (
            <Link key={index} href={data.url}>
              <Text color="text" fontWeight="medium" fontSize={'sm'}>
                {data.title}
              </Text>
            </Link>
          ))}
        </VStack>
      </VStack>
    </>
  );
};

export default ContactLia;

import { sendGAEvent } from '@next/third-parties/google';

const googleEventRemoveFromCart = (item: {
  id: string;
  title: string;
  price: number;
  payable_price: number;
  quantity: number;
}): any => {
  return sendGAEvent('event', 'remove_from_cart', {
    currency: 'IRR',
    value: item.payable_price * item.quantity,
    items: [
      {
        item_id: item.id,
        item_name: item.title,
        currency: 'IRR',
        discount: item.price - item.payable_price,
        price: item.payable_price,
        quantity: item.quantity,
      },
    ],
  });
};
export default googleEventRemoveFromCart;

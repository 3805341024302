import type { LocalBasketProduct } from '@/types';

import { sendGAEvent } from '@next/third-parties/google';

const googleEventViewCart = (
  basketProducts: LocalBasketProduct[],
  total: number
): any => {
  return sendGAEvent('event', 'view_cart', {
    currency: 'IRR',
    value: total,
    items: basketProducts.map((item) => ({
      item_id: item.id,
      item_name: item.title,
      disconnect: item.payable_price ? item.price - item.payable_price : 0,
      price: item.payable_price || item.price,
      quantity: item.quantity,
      item_category: item.category_code,
    })),
  });
};
export default googleEventViewCart;

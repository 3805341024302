import type { SocialNetworks } from '@/types';

import React from 'react';

import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from '@/components';
import { useTranslation } from '@/hooks';
import LogoLia from '@/svgs/liateam-logo.svg';

const TopSection: React.FC<{ socialNetworks: SocialNetworks }> = ({
  socialNetworks,
}) => {
  const { t } = useTranslation('components.footer');

  return (
    <>
      <Divider color="gray.700" />
      <Flex direction="column">
        <HStack
          alignItems={'center'}
          justifyContent={{ base: 'center', xl: 'space-between' }}
          m="20px 0"
        >
          <Box>
            <IconButton
              h={'93px'}
              w={'93px'}
              _hover={{ bgColor: 'transparent' }}
              aria-label="Logo Lia"
              bgColor={'transparent'}
              icon={
                <Link href="/" aria-label="logo">
                  <Box
                    style={{
                      paddingRight: 0,
                      border: 0,
                    }}
                  >
                    <LogoLia height={93} width={93} />
                  </Box>
                </Link>
              }
            />
          </Box>
          <Box></Box>
          <Box display={{ base: 'none', xl: 'flex' }} gap={'2.1rem'}>
            <VStack
              alignItems={'center'}
              justifyContent={'center'}
              w="max-content"
            >
              <Text color="text" fontSize="sm">
                {t('liateam')}
              </Text>
            </VStack>

            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              direction={'row'}
              fontSize="sm"
              spacing="14px"
              gap={'13.75px'}
            >
              {socialNetworks.map((item) => (
                <Link
                  href={item.link}
                  key={item.id}
                  target={'_blank'}
                  prefetch={false}
                >
                  <Box
                    backgroundColor="gray.700"
                    borderRadius="50%"
                    padding="7px"
                  >
                    <Image
                      src={item.icon}
                      alt={item.alt_icon}
                      width={20}
                      height={20}
                    />
                  </Box>
                </Link>
              ))}
            </Stack>
          </Box>
        </HStack>
        <Divider color="gray.700" display={{ base: 'none', md: 'block' }} />
      </Flex>
    </>
  );
};

export default TopSection;

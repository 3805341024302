import type { ProductGroup } from '@/types';

import { sendGAEvent } from '@next/third-parties/google';

const googleEventSelectItem = (product: ProductGroup): any => {
  return sendGAEvent('event', 'select_item', {
    eventName: 'select_item',
    currency: 'IRR',
    value: product.pricing?.payable_price || product.price,
    items: [
      {
        itemId: product.id,
        item_name: product.title,
        currency: 'IRR',
        price: product.pricing?.payable_price || product.price,
        discount: product.pricing?.discount_price || 0,
      },
    ],
  });
};
export default googleEventSelectItem;

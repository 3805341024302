import React from 'react';

import { Divider, HStack, Link, Text, VStack } from '@/components';
import { useTranslation } from '@/hooks';
import { toolsLinks } from '@/statics';

const Tools: React.FC = () => {
  const { t } = useTranslation('components.footer.tools');

  return (
    <>
      <VStack alignItems={'start'}>
        <Text
          color="text"
          fontWeight="medium"
          fontSize={'sm'}
          display={'flex'}
          alignItems={'start'}
          justifyContent={'start'}
        >
          {t('tools')}
        </Text>
        <Divider w="225px" color={'gray.700'} borderWidth={0.2} />
        <HStack alignItems={'start'} gap={'74'} mt={{ base: 0, md: '0.5rem' }}>
          <VStack
            spacing={'20px'}
            justifyContent={'start'}
            alignItems={'start'}
          >
            {toolsLinks.map((data, index) => (
              <Link prefetch={false} key={index} href={data.url || ''}>
                <Text
                  textAlign={'right'}
                  color="text"
                  fontWeight="medium"
                  fontSize={'sm'}
                >
                  {data.title}
                </Text>
              </Link>
            ))}
          </VStack>
        </HStack>
      </VStack>
    </>
  );
};

export default Tools;

import type { IHeaderMobile } from './data';

import MobileNotification from '../MobileNotification';

import {
  Box,
  HamburgerMenu,
  HeaderProfile,
  HeaderSearch,
  HStack,
  IconButton,
  Image,
  Link,
  MobileCart,
} from '@/components';
import { useProfile } from '@/hooks';
import BirthdayLogoLia from '@/images/anniversary/lia-birthday.png';
import WeddingLogoLia from '@/images/anniversary/lia-wedding.png';
import LogoLia from '@/svgs/liateam-logo.svg';

const HeaderMobile: IHeaderMobile = () => {
  const { data: profile } = useProfile();

  return (
    <Box
      display={{ base: 'block', md: 'none' }}
      px={4}
      bg="white"
      mb="16px"
      pb="16px"
    >
      <HStack justifyContent="space-between" pt={4}>
        <HamburgerMenu />
        {profile?.is_birthdate_anniversary ? (
          <Link href="/" aria-label="logo" mb={-2}>
            <Image src={BirthdayLogoLia} alt="liateam-logo" width={50} />
          </Link>
        ) : profile?.is_marriage_anniversary ? (
          <Link href="/" aria-label="logo" mb={-2}>
            <Image src={WeddingLogoLia} alt="liateam-logo" width={50} />
          </Link>
        ) : (
          <IconButton
            _hover={{ bgColor: 'transparent' }}
            aria-label="Logo Lia"
            bgColor="transparent"
            h="45px"
            w="45px"
            icon={
              <Link href="/" aria-label="logo">
                <Box
                  style={{ paddingRight: 0, border: 0 }}
                  height={45}
                  width={45}
                >
                  <LogoLia />
                </Box>
              </Link>
            }
          />
        )}
        <HeaderProfile />
      </HStack>
      <HStack alignItems={'center'} justify="space-between" mt={'18px'}>
        <HeaderSearch />
        <Box display={'flex'} alignItems={'center'} gap={2}>
          {profile && <MobileNotification />}
          <MobileCart />
        </Box>
      </HStack>
    </Box>
  );
};
export default HeaderMobile;

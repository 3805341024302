import type { ModalSearchProps } from './data';

import React from 'react';
import debounce from 'lodash/debounce';

import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Input,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  SearchProductCard,
  Spinner,
  Text,
} from '@/components';
import { useIsMobile, useSearch, useTranslation } from '@/hooks';
import NoResult from '@/images/header/noResult.png';
import { routes } from '@/statics';
import {
  googleEventSearch,
  googleEventViewSearchResult,
} from '@/utils/googleAnalyticsEvent';
import googleEventSelectItem from '@/utils/googleAnalyticsEvent/googleEventSelectItem';

const ModalSearch: ModalSearchProps = ({ isOpen, setIsOpen }) => {
  const [value, setValue] = React.useState('');
  const [debouncedValue, setDebouncedValue] = React.useState('');
  const isMobile = useIsMobile();
  const { t } = useTranslation('components.header');
  const { data, isLoading } = useSearch({
    fuzzy_search: debouncedValue,
    pageSize: isMobile ? 2 : 3,
  });

  React.useEffect(() => {
    if (debouncedValue) {
      googleEventSearch(debouncedValue);
    }
  }, [debouncedValue]);

  const debounceFn = React.useCallback(debounce(setDebouncedValue, 500), []);

  const handleInputChange = (inputValue: string) => {
    setValue(inputValue);
    if (inputValue && inputValue.trim().length > 0) {
      debounceFn(inputValue);
    }
  };

  const onClose = () => {
    setValue('');
    setIsOpen(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent justifyContent={'center'}>
          <Flex
            position="absolute"
            top="8vh"
            justifyContent={'center'}
            right="2%"
            left="2%"
          >
            <Box width="100%" maxWidth="830px">
              <Box mx={2}>
                <Input
                  border="none"
                  shadow="none"
                  _focus={{
                    boxShadow: 'none',
                    background: 'white',
                  }}
                  bgColor="white"
                  focusBorderColor="none"
                  onChange={(e) => handleInputChange(e.target.value)}
                  placeholder={t('searchPlaceholder')}
                  borderRadius={{
                    base: value.length > 0 ? '9px' : 'curved',
                    md: '9px',
                  }}
                  isSearch={true}
                  value={value}
                  h={{ base: '40px', md: '64px' }}
                />
              </Box>
              {value && (
                <Box background="white" borderRadius="sm" marginInline={2}>
                  {value === debouncedValue &&
                  !isLoading &&
                  data?.list &&
                  data?.list.length > 0 ? (
                    data?.list.map((product, index) => {
                      return (
                        <Box key={product.id}>
                          <Box
                            onClick={() => {
                              setIsOpen(false);
                              googleEventSelectItem(product);
                            }}
                          >
                            <SearchProductCard
                              image={product.home_page_image}
                              alt={product.alt_home_page_image}
                              title={product.title}
                              code={product.code}
                            />
                          </Box>
                          {index !== data.list.length - 1 && (
                            <Box paddingInline="16px">
                              <Divider variant="dashed" />
                            </Box>
                          )}
                        </Box>
                      );
                    })
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="255px"
                    >
                      {value === debouncedValue && !isLoading ? (
                        <Box>
                          <Image
                            src={NoResult}
                            alt="noResult"
                            width="195"
                            height="113.5"
                          />
                          <Text
                            fontSize="md"
                            paddingBlock="16px"
                            textAlign="center"
                          >
                            {t('hasNotResponse')}
                          </Text>
                        </Box>
                      ) : (
                        <Text fontSize="md" paddingBlock="16px">
                          <Spinner
                            color="primary.500"
                            size="xl"
                            thickness="4px"
                          />
                        </Text>
                      )}
                    </Box>
                  )}
                </Box>
              )}
              {value &&
                value === debouncedValue &&
                !isLoading &&
                data?.list &&
                data?.list.length > 0 && (
                  <Box display="flex" justifyContent="center" marginTop="16px">
                    <Button
                      href={`${routes.search.url}?search=${value}`}
                      as={Link}
                      width={{ base: '100%', md: 'max-content' }}
                      size={{ base: 'sm', md: 'md' }}
                      onClick={() => {
                        setIsOpen(false);
                        setValue('');
                        googleEventViewSearchResult(value);
                      }}
                    >
                      {t('viewAll')}
                    </Button>
                  </Box>
                )}
            </Box>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ModalSearch;

import type { IMobileCart } from './types';

import React from 'react';
import { useShallow } from 'zustand/react/shallow';

import HeaderBasket from '../HeaderBasket';

import {
  Box,
  Button,
  CartProduct,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  HStack,
  Icon,
  Link,
  Text,
  VStack,
} from '@/components';
import {
  useBasketStore,
  useDisclosure,
  useProfile,
  useTranslation,
} from '@/hooks';
import { routes } from '@/statics';
import CartHeaderIcon from '@/svgs/cart-header.svg';
import {
  separator,
  totalBasketPrice,
  totalCV,
  totalPV,
  toToman,
} from '@/utils';
import { googleEventViewCart } from '@/utils/googleAnalyticsEvent';

const MobileCart: IMobileCart = () => {
  const { t } = useTranslation('components.header.cart');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: profile } = useProfile();
  const products = useBasketStore(useShallow((state) => state.products));

  return (
    <>
      <HeaderBasket
        onClick={() => {
          onOpen();
          googleEventViewCart(products, totalBasketPrice({ products }));
        }}
      />
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} size="full">
        <DrawerContent maxH="full">
          <DrawerCloseButton />
          <DrawerHeader
            borderBottomWidth="1px"
            justifyContent="center"
            display="flex"
            mx={5}
          >
            <Icon as={CartHeaderIcon} w={20} h={24} />
          </DrawerHeader>
          <DrawerBody paddingInline={0} paddingTop="20px">
            {products.length === 0 && (
              <VStack w="full" pt={20}>
                <Text fontSize="sm" color="gray.800">
                  {t('yourCartIsEmpty')}
                </Text>
              </VStack>
            )}
            {products.map((product) => (
              <Box key={product.id} paddingBlock="11px">
                <CartProduct afterClick={onClose} product={product} />
              </Box>
            ))}
          </DrawerBody>
          {products.length > 0 && (
            <DrawerFooter
              px={5}
              pt={4}
              pb={6}
              borderTopColor="gray.600"
              borderTopWidth="1px"
            >
              <VStack w="full">
                {profile?.user_type === 'agent' && (
                  <HStack
                    justify="space-between"
                    w="full"
                    paddingInline={4}
                    borderBottomColor="gray.300"
                    borderBottomWidth="1px"
                    pb={2}
                  >
                    <Text
                      dir="ltr"
                      fontSize="sm"
                      fontWeight="bold"
                      color="gray.800"
                    >
                      {t('cv')}: {separator(totalCV({ products }))}
                    </Text>
                    <Text
                      dir="ltr"
                      fontSize="sm"
                      fontWeight="bold"
                      color="gray.800"
                    >
                      {t('pv')}: {separator(totalPV({ products }))}
                    </Text>
                  </HStack>
                )}
                <HStack
                  direction="row"
                  align="center"
                  justify="space-between"
                  w="full"
                  pt={5}
                >
                  <VStack gap="1" align="flex-start" color="gray.800">
                    <Text fontWeight="medium" color="gray.700">
                      {t('total')}:
                    </Text>
                    <Text fontWeight="medium" color="black">
                      {toToman(totalBasketPrice({ products }))}
                    </Text>
                  </VStack>
                  <Button
                    onClick={onClose}
                    colorScheme="secondary"
                    as={Link}
                    href={routes.checkout.url}
                    position="relative"
                  >
                    {t('submitOrder')}
                  </Button>
                </HStack>
              </VStack>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileCart;

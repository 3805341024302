import type { IMenu } from './types';

import { Menu as CkMenu } from '@chakra-ui/react';

const Menu: IMenu = ({ children, ...props }) => {
  return (
    <CkMenu isLazy {...props}>
      {children}
    </CkMenu>
  );
};

export default Menu;
